export enum ROUTES {
  ROOT = "/",
  MYPRODUCT = "my-product",
  SHOW_ROOM = "show-room",
  SHOW_ROOM_COMPARE = "show-room-compare",
  FINANCE = "/finance",
  COMPARE = "compare",
  CRO = "CRO-module",
  CRO_OPTIMISATIONDETAINS = "cro-listing-optimisation",
  CRO_PHOTOOPTIMISATION = "cro-photo-optimisation",
  CRO_VIDEOOPTIMISATION = "cro-video-optimisation",
  AUTH_ACTIVATE = "activate/:uid/:token",
  WELCOME = "welcome",
  AUTH = "/auth",
  AUTH_LOGIN = "login",
  AUTH_FORGOT = "forgot",
  AUTH_SIGNUP = "signup",
  AUTH_CONFIRMED = "/confirmed",
  AUTH_PASSWORDCONFIRMED = "/password-confirmed",
  AUTH_SIGNUPSUCCESSFUL = "successful-registration",
  AUTH_PASSWORDRESET = "/password/reset/confirm",
  AUTH_CALLBACK = "callback",
  ADS_ANALYTICS = "ads-analytics",
  REFERRAL_PAGE = "referral-page",
  AMAZON_ONBOARDING = "amazon-onboarding",
  AMAZON_ONBOARDING_COMPLETE = "amazon-onboarding-complete",
  CONNECT_AMAZON = "connect-amazon",
  REDIRECT_TO_SELLER = "redirect-to-seller",
  REDIRECT_TO_AMAZON = "redirect-to-amazon",
  CONGRATULATIONS_CONNECT = "congratulations-connect",
  SETTINGS_ACCOUNT = "/settings/account",
  SETTINGS_PLAN = "/settings/plan",
  SETTINGS_TEAM = "/settings/team",
}

export enum ADS_ANALITICS_ROUTES {
  PORTFOLIO = "portfolios",
  CAMPAIGNS = "campaigns",
  PRODUCTS = "products",
  SANKEY = "sankey",
  HEATMAP = "heatmap",
  PLACEMENT = "placement",
}
