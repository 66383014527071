import React, { useEffect, useState } from "react";
import RecommendationSection from "@pages/CROModule/components/Product/Optimisation/components/RecommendationSection";
import { ApiResponse } from "src/types/apiResponce";
import { AnalyzeProductPhoto } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { OptimizationService } from "@services/optimization/optimization.services";

interface FormSectionProps {
  id: string;
}

const FormSection: React.FC<FormSectionProps> = ({ id }) => {
  const [analyzeData, setAnalyzeData] =
    useState<ApiResponse<AnalyzeProductPhoto> | null>(null);
  const [recommendationData, setRecommendationData] = useState<string | null>(
    null,
  );
  const { selectedProduct } = useProductStore();
  const optimizationService = new OptimizationService();

  useEffect(() => {
    if (!selectedProduct?.asin) return;

    const fetchPhotoAnalysis = async () => {
      try {
        const res = await optimizationService.getAnalyzePhotoByAsin(
          selectedProduct.asin,
        );
        setAnalyzeData(res as any);
      } catch (error) {
        console.error("Error fetching product analysis:", error);
      }
    };

    fetchPhotoAnalysis();
  }, [selectedProduct]);

  const fetchAnalyzeSuggestions = async () => {
    try {
      if (!selectedProduct?.asin) return;

      const optimizationService = new OptimizationService();
      const res = await optimizationService.getAnalyzePhotoSuggestionsByAsin(
        selectedProduct.asin,
        100,
      );
      setRecommendationData(res as any);
    } catch (error) {
      console.error("Error fetching product analysis:", error);
    }
  };

  return (
    <RecommendationSection
      analyzeData={analyzeData}
      recommendationData={recommendationData}
      onToggleRecommendation={fetchAnalyzeSuggestions}
      id={id}
    />
  );
};

export default FormSection;
